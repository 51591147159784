<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">

            <!-- Brand logo-->
            <b-link class="brand-logo ">
                <!-- <vuexy-logo /> -->

                <h1 class="brand-text text-primary ml-1" style="font-size:35px;">
                    UMKM Sambirejo Semarang<small></small>
                </h1><br>
            </b-link>
            <!-- /Brand logo-->

            <!-- Left Text-->
            <!-- <b-col lg="6" class="d-none d-lg-flex align-items-center p-5">
                <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                    <b-img fluid :src="imgUrl" alt="Register V2" />
                </div>
            </b-col> -->
            <!-- /Left Text-->
            <!-- Register-->
            <b-col lg="12" class="d-flex align-items-center auth-bg px-2 p-lg-5" v-if="st == '0'">
                <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
                    <b-img fluid :src="imgUrl" alt="Register V2" />


                    <p class="text-center mt-2">
                        <span>Sudah Memiliki Akun ?</span>
                        <router-link to="/">
                            <span>&nbsp;Silahkan Login disini</span>
                        </router-link>

                    </p>

                    <!-- divider -->
                    <div class="divider my-2">
                        <div class="divider-text">

                        </div>
                    </div>


                </b-col>
            </b-col>
            <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5" v-if="st == '1'">
                <registrasi_info :info=info v-if="st == '1'"></registrasi_info>
            </b-col>
            <!-- /Register-->
        </b-row>

    </div>
</template>

<script>
import Base from '@/config/Mixins_base';
import registrasi_info from './RegistrasiInfo.vue';
/* eslint-disable global-require */
import axios from '@/config/Axios'
import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
    BRow,
    BCol,
    BLink,
    BButton,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BCardTitle,
    BCardText,
    BFormSelect
} from 'bootstrap-vue'
import {
    required,
    email
} from '@validations'
import {
    togglePasswordVisibility
} from '@core/mixins/ui/forms'
import store from '@/store/index'

export default {
    components: {
        VuexyLogo,
        BRow,
        BImg,
        BCol,
        BLink,
        BButton,
        BForm,
        BCardText,
        BCardTitle,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupAppend,
        // validations
        ValidationProvider,
        ValidationObserver,
        registrasi_info,
        BFormSelect
    },
    mixins: [togglePasswordVisibility, Base],
    data() {
        return {
            rs_kelas: [{
                value: 'Pagi',
                text: 'Pagi'
            },
            {
                value: 'Sore',
                text: 'Sore'
            },
            {
                value: 'Karyawan',
                text: 'Karyawan'
            },
            ],
            registrasi: {},
            cek_nim: 1,
            username_info: {},
            status: '',
            username: '',
            userEmail: '',
            password: '',
            sideImg: require('@/assets/images/pages/registrasi.png'),
            required,
            email,
            st: "0",
            info: {}
        }
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        imgUrl() {
            if (store.state.appConfig.layout.skin === 'dark') {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
                return this.sideImg
            }
            return this.sideImg
        },
    },
    mounted() {
        this.st = '0';
    },
    methods: {
        async cekNim() {
            const self = this;
            const pj = self.registrasi.nim;
            if (pj.length >= 13) {
                await axios({
                    method: 'PUT',
                    url: '/api/registrasi/cek_nm_kelompok',
                    data: {
                        nama_kelompok: self.registrasi.nama_kelompok
                    },
                    headers: {

                    }
                })
                    .then(function (response) {
                        self.cek_nim = response.data.result;
                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            }


        },
        async cek_username() {
            const self = this;
            await axios({
                method: 'PUT',
                url: '/api/registrasi/cek_username',
                data: {
                    username: self.registrasi.username
                },
                headers: {

                }
            })
                .then(function (response) {
                    self.username_info = response.data.result;
                }).catch(err => {
                    self.pesan = err.message;
                    self.notification('warning', "Error", err.message);
                });
        },
        async act_register() {
            const self = this;
            await axios({
                method: 'POST',
                url: '/api/registrasi/add_user',
                data: self.registrasi,
                headers: {
                    'Authorization': self.isAuthenticated
                }
            })
                .then(function (response) {
                    self.info = response.data.result;
                    self.st = '1';
                    self.notification('primary', "Selamat..", response.data.message);

                }).catch(err => {
                    self.pesan = err.message;
                    self.notification('warning', "Error", err.message);
                });
        },
        validationForm() {
            this.$refs.registerForm.validate().then(success => {
                if (success) {
                    this.act_register();
                    // this.$router.push({
                    //     name: 'registrasi_info'
                    // })
                }
            })
        },
    },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>